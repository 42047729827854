<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3>
          Прикрепить файлы
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col
          class="white form__files"
          cols="12"
        >
          <div
            v-for="(item, i) in files"
            :key="i"
            class="d-flex white"
          >
            <v-col
              cols="12"
              lg="6"
            >
              <label for="">Приложите документ</label>
              <v-file-input
                v-model="item.file"
                dense
                outlined
                color="#32a1af"
                solo
                flat
                multiple
                placeholder="Для выбора нескольких файлов удерживайте клавишу Ctrl"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <label for="">Введите название документа</label>
              <v-text-field
                v-model="item.fileName"
                color="#32a1af"
                background-color="#fff"
                solo
                hide-details="auto"
                class="mb-2"
                flat
                outlined
                dense
              >
                <template slot="append-outer">
                  <v-icon
                    v-if="files.length > 1"
                    @click="deleteDoc(i)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    v-if="i == files.length - 1"
                    class="ml-2"
                    @click="addDoc"
                  >
                    mdi-plus
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </div>
        </v-col>


        <div class="d-flex justify-end">
          <v-btn
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";

export default {
  mixins:[modalWindowMixin],
  props:{
    id:{
      type:Number,
      required:true,
    }
  },
  data:() => ({
    files:[{
      file:null,
      fileName:""
    }]
  }),
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }
    }
  },
  methods:{
    unset(){
      this.files = [
        {
          file:null,
          fileName:""
        }
      ]
    },
    submit(){
      if(this.id< 0) return false;

      const formData = new FormData()
      const names = []
      this.files.forEach((doc, idx) => {
        if(doc.file){
          doc.file.forEach((item, index) =>{
            const key = `file-${idx}-${index}`
            formData.append(key, item)
            names.push({key, name:doc.fileName})
          })
        }
      })
      formData.append('names', JSON.stringify(names))
      formData.append('declarationId',this.id.toString())

      this.$store.dispatch('zvt/addDocuments',formData).then(() => {
        this.$success("Документы добавлены");
        this.$store.dispatch('journal/fetchItems')
        this.close()
      }).catch(() => this.$error())
    },
    deleteDoc(i){
      this.files.splice(i, 1)
    },
    addDoc(){
      this.files.push({ file: null, fileName: ''})
    }
  }


}
</script>
<style scoped>

.form__files{
    height: 300px!important;
    overflow-y: scroll;
}
</style>
